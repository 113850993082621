import { React } from "react";
import Home from "./pages/Home";
import Formation from "./pages/Formation";
import Experience from "./pages/Experience";
import Skills from "./pages/Skills";
import Contact from "./pages/Contact";
import Header from "./components/Header";
import { Routes, Route } from 'react-router-dom';
import { HelmetProvider } from "react-helmet-async";
import { useCallback } from "react";
import Particles from "react-tsparticles";
import { loadSlim } from "tsparticles-slim";


const Night = (props) => {
    return (
        <Particles
            id="tsparticles"
            init={props.init}
            theme={props.theme}
            options={{
                particles: {
                    number: {
                        value: 80, density: { enable: true, value_area: 800 }
                    },
                    color: { value: "#a45ee5" },
                    shape: {
                        type: "circle",
                        stroke: { width: 0, color: "#000000" },
                        polygon: { nb_sides: 5 },
                        image: { src: "img/github.svg", width: 100, height: 100 },
                    },
                    opacity: {
                        value: 0.5,
                        random: false,
                        anim: { enable: false, speed: 1, opacity_min: 0.1, sync: false },
                    },
                    size: {
                        value: 3,
                        random: true,
                        anim: { enable: false, speed: 40, size_min: 0.1, sync: false },
                    },
                    line_linked: {
                        enable: false,
                        distance: 150,
                        color: "#ffffff",
                        opacity: 0.4,
                        width: 1,
                    },
                    move: {
                        enable: true,
                        speed: 0.5,
                        direction: "none",
                        random: true,
                        straight: false,
                        out_mode: "out",
                        bounce: false,
                        attract: { enable: false, rotateX: 600, rotateY: 1200 },
                    },
                },
                interactivity: {
                    detect_on: "canvas",
                    events: {
                        onhover: { enable: false, mode: "grab" },
                        onclick: { enable: false, mode: "push" },
                        resize: true,
                    },
                    modes: {
                        grab: { distance: 400, line_linked: { opacity: 1 } },
                        bubble: {
                            distance: 400,
                            size: 40,
                            duration: 2,
                            opacity: 8,
                            speed: 3,
                        },
                        repulse: { distance: 200, duration: 0.4 },
                        push: { particles_nb: 4 },
                        remove: { particles_nb: 2 },
                    },
                },
                retina_detect: false,
            }
            }
        />
    );
}



const App = () => {
    document.getElementById('root').classList.add('Dark');

    const particlesInit = useCallback(async engine => {
        await loadSlim(engine);
    }, []);

    const helmetContext = {};

    return (
        <HelmetProvider context={helmetContext}>
            <Header />
            <main>
                <Night init={particlesInit} />
                <div>
                    <Routes>
                        <Route path="*" element={<Home />} />
                        <Route exact path="/" element={<Home />} />
                        <Route path="/Home" element={<Home />} />
                        <Route path="/Formation" element={<Formation />} />
                        <Route path="/Experience" element={<Experience />} />
                        <Route path="/Skills" element={<Skills />} />
                        <Route path="/Contact" element={<Contact />} />
                    </Routes>
                </div>
            </main>
        </HelmetProvider>
    );
};

export default App;
