import React, { createContext, useState } from 'react';

const DarkLightSwitch = createContext();

const DarkLightSwitchProvider = (props) => {
    const [darkMode, setDarkMode] = useState(false);
    const toggleDarkMode = () => {
        setDarkMode(!darkMode);
    };

    return (
        <DarkLightSwitch.Provider value={[darkMode, toggleDarkMode]}>
            {props.children}
        </DarkLightSwitch.Provider>
    );
};

export { DarkLightSwitch, DarkLightSwitchProvider };