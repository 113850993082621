import { React } from "react";
import { Helmet } from 'react-helmet-async';

function Content(props) {

    return (
        <ul className='Content'>
            <li><p><i className={props.icon}></i><span>{props.Text}</span></p></li>
        </ul>
    );
}

const Home = () => {

    const cont = Object.keys(content).map((items) =>
        <Content key={items} Text={content[items].Text} icon={content[items].icon} />
    );

    return (
        <>
            <Helmet>
                <title>Home</title>
                <meta name='description' content='Home' />
            </Helmet>
            <div className="Home">
                <div className="Container text-center">
                    <div className="row justify-content-center">
                        <div className="Title underline">
                            <h1>Quentin Stubecki</h1>
                        </div>
                    </div>
                    <div className="row justify-content-center">
                        <div className="col-xl-4">
                            <div className='About'>
                                {cont}
                            </div>
                        </div>
                    </div>
                    <div className="row justify-content-center">
                        <div className="Description">
                            <a href='https://quentinstubecki.fr/resume.pdf' className="aled" target="_blank" rel="noreferrer">
                                <p><i className="bi bi-file-earmark-arrow-down"></i><span>Resume</span></p></a>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

var content = [
    {
        Text: "Aspiring Data Scientist",
        icon: "bi bi-balloon-fill"
    },
    {
        Text: "CESI Engineering School",
        icon: "bi bi-mortarboard-fill"
    },
    {
        Text: "Strasbourg, France",
        icon: "i bi-geo-alt-fill"
    },
    {
        Text: "Learning ReactJS",
        icon: "bi bi-stars"
    }
]
export default Home;