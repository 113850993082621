import { React } from "react";
import { Helmet } from 'react-helmet-async';

function Exp(props) {
    var showLength = props.Length ? <span className='Length'> - {props.Length}</span> : null;
    return (
        <div className='container text-center text-size'>
            <div className='row'>
                <div className={props.side ? "col-md-6 order-1" : "col-md-6 order-2"} style={{ textAlign: props.side ? "Right" : "Left" }}>
                    <p className='Year'>{props.Year}{showLength}</p>
                    <p className='Company underline'>
                        <a href={props.Website}>
                            <i className="bi bi-arrow-up-right-circle"></i>
                            <span>{props.Company}</span>
                        </a>
                    </p>
                    <p className='Title'>{props.Title}</p>
                    <p className='Location'><i className="i bi-geo-alt-fill"></i>{props.Location}</p>
                </div>
                <div className={props.side ? "col-md-6 order-2 Description" : "col-md-6 order-1 Description"} style={{ textAlign: props.side ? "Left" : "Right" }}>
                    <p>{props.Description}</p>
                </div>
            </div>
        </div>
    );
}

const Formation = () => {

    const exp = Object.keys(formation).map((items) =>
        <Exp key={items} item={items} Year={formation[items].Year} Length={formation[items].Length} Location={formation[items].Location} Title={formation[items].Title} Company={formation[items].Company} Website={formation[items].Website} Description={formation[items].Description} side={items % 2 ? false : true} />
    );

    return (
        <>
            <Helmet>
                <title>Formation</title>
                <meta name='description' content='Formation' />
            </Helmet>
            <div className='Formation'>
                <div>
                    {exp}
                </div>
            </div>
        </>
    );
};

var formation = [
    {
        Year: "Since 2022",
        Location: "Strasbourg, France",
        Title: "Engineering cycle in computer science",
        Company: "CESI Engineering School",
        Website: "https://www.cesi.fr/",
        Description: "Engineering school with a 3 years cycle, to obtain a Master's degree in computer science. I am currently in my 1st year of the cycle.",
    },
    {
        Year: "2020 - 2022",
        Location: "Strasbourg, France",
        Title: "Preparatory cycle in computer science",
        Company: "CESI Engineering School",
        Website: "https://www.cesi.fr/",
        Description: "Preparatory cycle with a 2 years cycle, to obtain a Bachelor's degree in computer science. I already passed both years of the cycle, and moved to the engineering cycle.",
    },
    {
        Year: "2017 - 2020",
        Location: "Mulhouse, France",
        Title: "French Baccalauréat degree in General Science",
        Company: "Albert Schweitzer High School",
        Website: "http://las.websco.fr/",
        Description: "French Baccalaureate degree in General Science, Computer Science minor - with honors",
    },
    {
        Year: "2018",
        Location: "Mulhouse, France",
        Title: "Cambridge English First Certificate (B2)",
        Company: "Albert Schweitzer High School",
        Website: "http://las.websco.fr/",
        Description: "First Certificate in English (FCE) is an internationally recognised qualification that shows you have mastered the basics of the English language. It is accepted by universities, employers and governments around the world.",
    }
]

export default Formation;