import React, { useContext } from 'react';
import { DarkLightSwitch } from '../context/DarkLightSwitch';

const ThemeSwitch = (props) => {
    const [darkMode, toggleDarkMode] = useContext(DarkLightSwitch);
    document.getElementById('root').classList.toggle('Dark', !darkMode);
    document.getElementById('root').classList.toggle('Light', darkMode);

    return (
        <div>
            <i onClick={toggleDarkMode} className={darkMode ? 'bi bi-moon-stars-fill' : 'bi bi-brightness-high-fill'}></i>
            {props.children}
        </div>
    );
};

export default ThemeSwitch;