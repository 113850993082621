import { React } from "react";
import { Helmet } from 'react-helmet-async';

function Exp(props) {
	var showLength = props.Length ? <span className='Length'> - {props.Length}</span> : null;
	return (
		<div className='container text-center text-size'>
			<div className='row'>
				<div className={props.side ? "col-md-6 order-1" : "col-md-6 order-2"} style={{ textAlign: props.side ? "Right" : "Left" }}>
					<p className='Year'>{props.Year}{showLength}</p>
					<p className='Company underline'>
						<a href={props.Website}>
							<i className="bi bi-arrow-up-right-circle"></i>
							<span>{props.Company}</span>
						</a>
					</p>
					<p className='Title'>{props.Title}</p>
					<p className='Location'><i className="i bi-geo-alt-fill"></i>{props.Location}</p>
					<p className='Skillse'><i className="i bi-code-slash"></i>{props.Skills}</p>
				</div>
				<div className={props.side ? "col-md-6 order-2 Description" : "col-md-6 order-1 Description"} style={{ textAlign: props.side ? "Left" : "Right" }}>
					<p>{props.Description}</p>
				</div>
			</div>
		</div>
	);
}
const Experience = () => {

	const exp = Object.keys(experience).map((items) =>
		<Exp key={items} item={items} Year={experience[items].Year} Length={experience[items].Length} Location={experience[items].Location} Title={experience[items].Title} Company={experience[items].Company} Website={experience[items].Website} Description={experience[items].Description} Skills={experience[items].Skills} side={items % 2 ? false : true} />
	);

	return (
		<>
			<Helmet>
				<title>Formation</title>
				<meta name='description' content='Experience' />
			</Helmet>
			<div className='Experience'>
				<div>
					{exp}
				</div>
			</div>
		</>
	);
};

var experience = [
	{
		Year: "2023",
		Length: "14 weeks",
		Location: "Kehl, Germany",
		Title: "Computer Vision Intern Developer",
		Company: "ADDI-DATA GmbH",
		Website: "https://www.addi-data.com/en/",
		Description: "First working experience in the field of computer vision. My role was to develop a demo of a project in the field of industrial automation. Quite a challenge since i had no prior experience in this field, but i learned a lot.",
		Skills: "Python, OpenCV, Git, Linux, Agile Methodology"
	},
	{
		Year: "2022",
		Length: "15 weeks",
		Location: "Mulhouse, France",
		Title: "Software Developer Intern",
		Company: "EL2I Informatique",
		Website: "https://www.el2i.fr/",
		Description: "First working experience in IT as a developer. My role was to develop new features on an existing software. I was also in charge of the maintenance of the software.",
		Skills: "Delphi, SQL, Git"
	},
	{
		Year: "2020",
		Length: "1 month",
		Location: "Thann, France",
		Title: "Production Operator",
		Company: "Vynova ppc",
		Website: "https://www.vynova-group.com/fr/sites/thann",
		Description: "First working experience ever. My role was to operate a machine that produces chemical products. It taught me a lot about life in a company and the importance of teamwork.",
		Skills: "Teamwork, Respect, Punctuality"
	},
]

export default Experience;