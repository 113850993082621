/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { NavLink } from 'react-router-dom';
import Navbar from 'react-bootstrap/Navbar';

const Navigation = () => {

    return (
        <div className="navigation">
            <Navbar className="pos-f-t" expand="lg">
                <Navbar.Toggle className="nav-toggle" aria-controls="a">
                    <i className="bi bi-list-nested"></i>
                </Navbar.Toggle>
                <Navbar.Collapse className="nav-collapse" id="basic-navbar-nav">
                    <NavLink to="/" className={(nav) => (nav.isActive ? "nav-active" : "")} end>
                        <li>Home</li>
                    </NavLink>
                    <NavLink to="/Formation" style={{ display: 'block' }} className={(nav) => (nav.isActive ? "nav-active" : "")}>
                        <li>Formation</li>
                    </NavLink>
                    <NavLink to="/Experience" style={{ display: 'block' }} className={(nav) => (nav.isActive ? "nav-active" : "")}>
                        <li>Experience</li>
                    </NavLink>
                    <NavLink to="/Skills" style={{ display: 'block' }} className={(nav) => (nav.isActive ? "nav-active" : "")}>
                        <li>Skills</li>
                    </NavLink>
                    <NavLink to="/Contact" style={{ display: 'block' }} className={(nav) => (nav.isActive ? "nav-active" : "")}>
                        <li>Contact</li>
                    </NavLink>
                </Navbar.Collapse>
            </Navbar>
        </div>
    );
};

export default Navigation;