import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import "./styles/index.scss";
import App from "./App";
import { DarkLightSwitchProvider } from "./context/DarkLightSwitch";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <DarkLightSwitchProvider>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </DarkLightSwitchProvider>
  </React.StrictMode>
);
