import { React } from "react";
import { Helmet } from 'react-helmet-async';

function Box(props) {
    return (
        <div className='col-4 adjust-size'>
            <a href={props.link} target="_blank" rel="noreferrer" className="aled">
                <p><i className={props.icon}></i><span>{props.name}</span></p>
            </a>
        </div>
    );
}

function Contact() {

    const Contacts = Object.keys(boxes).map((items) =>
        <Box key={items} item={items} name={boxes[items].name} link={boxes[items].link} icon={boxes[items].icon} />
    );

    return (
        <>
            <Helmet>
                <title>Contact</title>
                <meta name='description' content='Contact' />
            </Helmet>

            <div className='Contact'>
                <div className='container text-center'>
                    <div className="row justify-content-center Title">
                        <h1>
                            Contact me!
                        </h1>
                    </div>
                    <div className="row justify-content-center links">
                        {Contacts}
                    </div>
                </div>
            </div>
        </>
    );
};

const boxes = {
    "Github": {
        name: "Github",
        link: "https://github.com/Zepyyy",
        icon: "bi bi-github"
    },
    "Linkedin": {
        name: "LinkedIn",
        link: "https://www.linkedin.com/in/quentin-stubecki-211419227/",
        icon: "bi bi-linkedin"
    },
    "Mail": {
        name: "Mail",
        link: "mailto:quentin.stubecki@viacesi.fr",
        icon: "bi bi-envelope-fill"
    },
};

export default Contact;