import React from "react";
import Navigation from "./Navigation";
import ThemeSwitch from "./ThemeSwitch";

const Header = () => {
    return (
        <header>
            <div className="row justify-content-center">
                <div className="col Theme">
                    <ThemeSwitch />
                </div>
                <div className="col-6 Links">
                    <Navigation />
                </div>
                <div className="col" />
            </div>
        </header>
    );
};

export default Header;