import { React } from "react";
import { Helmet } from 'react-helmet-async';

function Content(props) {
    const Description =
        <div className='Description'>
            <ul>
                {props.description.map((items) =>
                    <li key={items}><p>{items}</p></li>
                )}
            </ul>
        </div>



    const Title =
        <div className='underline'>
            <h2><i className={props.icon}></i><span>{props.title}</span></h2>
        </div>


    return (
        <div className='row'>
            <div className='col-md-6' >
                {Title}
            </div>
            <div className='col-md-6'>
                {Description}
            </div>
        </div>
    );
}

const Skills = () => {

    const cont = Object.keys(content).map((items) =>
        <Content key={items} item={items} title={content[items].title} description={content[items].description} icon={content[items].icon} />
    );

    return (
        <>
            <Helmet>
                <title>Skills</title>
                <meta name='description' content='Skills' />
            </Helmet>
            <div className='Skills'>
                <div className='container text-center text-size'>
                    {cont}
                </div>
            </div>
        </>
    );
};

const content = {

    web: {
        title: "Web",
        text: "Web developer",
        description: ["HTML5", "CSS3", "PHP", "JS", "React", "Bootstrap"],
        icon: "bi bi-globe"
    },
    computerVision: {
        title: "Computer Vision",
        text: "Software developer",
        description: ["Python", "Yolov8", "Pytorch", "OpenCV"],
        icon: "bi bi-person-bounding-box"
    },
    software: {
        title: "Software",
        text: "Software developer",
        description: ["C++ / C# (.Net)", "Delphi", "Visual Studio Code", "Visual Studio", "Git"],
        icon: "bi bi-code-square"
    },
    languages: {
        title: "Languages",
        text: "Languages",
        description: ["French (Native)", "English (Full professional proficiency)", "German (Basic)"],
        icon: "bi bi-chat-left-text-fill"
    },
    adminSys: {
        title: "Admin Sys",
        text: "System administrator",
        description: ["Linux", "Windows", "Active Directory", "Apache"],
        icon: "bi bi-person-gear"
    },
    network: {
        title: "Network",
        text: "Network administrator",
        description: ["TCP/IP", "DNS", "DHCP", "Firewall", "VPN"],
        icon: "bi bi-router"
    },
    database: {
        title: "Database",
        text: "Database administrator",
        description: ["MySQL", "SQL Server"],
        icon: "bi bi-database-check"
    },
    project: {
        title: "Project",
        text: "Project manager",
        description: ["Agile", "Scrum", "Kanban", "Jira", "Confluence", "Trello"],
        icon: "bi bi-clipboard-check"
    }
};

export default Skills;